// Import statements
import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for React 18
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./components/ErrorBoundary.react";
import App from "./App"; // Adjust the import path if necessary
import { persistor, store } from "./config/store/app.store";
import reportWebVitals from "./reportWebVitals";

// Get your root container
const container = document.getElementById("root");

// Create a root using the new API
const root = ReactDOM.createRoot(container);

// Render your app
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
