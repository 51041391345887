import React, { useState, useEffect } from "react";
import Avatar from "avataaars2";
import { Image } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
import { gradient } from "@utilities/helpers";
import { avatarSchema } from "@schemas/avatarSchema";

export default function UserAvatar(props) {
    const { name, image, size, border, avatarConfig } = props;
    const [avatarData, setAvatarData] = useState(avatarConfig);

    useEffect(() => {
        if (avatarConfig != null) {
            setAvatarData(avatarConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarConfig]);

    const generateNumbers = (name) => {
        const numbers = [];

        while (numbers.length < 10) {
            const index = numbers.length % name.length;
            const charCode = name.charCodeAt(index);
            const number = charCode % 7;
            numbers.push(number);
        }

        return numbers;
    };

    // Generate a hash code from the text
    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    // Generate a unique light pastel color code based on the hash code
    const generateColorCode = (hashCode) => {
        const saturation = "70%";
        const lightness = "80%";
        const hue = hashCode % 360;
        return `hsl(${hue}, ${saturation}, ${lightness})`;
    };

    const colorCode = generateColorCode(hashCode(name));

    useEffect(() => {
        if (avatarData == null) {
            const numArray = generateNumbers(name);
            setAvatarData({
                background: colorCode,
                topType: avatarSchema.topType.male[numArray[0]],
                accessoriesType: avatarSchema.accessoriesType.male[numArray[1]],
                hairColor: avatarSchema.hairColor.male[numArray[2]],
                facialHairType: avatarSchema.facialHairType.male[numArray[3]],
                clotheType: avatarSchema.clotheType.male[numArray[4]],
                clotheColor: avatarSchema.clotheColor.male[numArray[5]],
                eyeType: avatarSchema.eyeType.male[numArray[6]],
                eyebrowType: avatarSchema.eyebrowType.male[numArray[7]],
                mouthType: avatarSchema.mouthType.male[numArray[8]],
                skinColor: avatarSchema.skinColor.male[numArray[9]],
            });
        }
    }, [avatarData, colorCode, name]);

    // ========================= Render Function =================================
    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                height: `${size}px`,
                overflow: "hidden",
            }}
        >
            {image == null || image === "" ? (
                <div
                    className="avatar-icon"
                    style={{
                        border: `5px solid ${border}`,
                    }}
                >
                    <Div
                        centered
                        pureCircle
                        wd={`${size || 30}px`}
                        ht={`${size || 30}px`}
                        style={{
                            background: gradient(
                                avatarData?.background || "#ffffff"
                            ),
                        }}
                    >
                        {avatarData && (
                            <Avatar
                                style={{
                                    width: `${size || 30}px`,
                                    height: `${size || 30}px`,
                                }}
                                avatarStyle="transparent"
                                topType={avatarData.topType}
                                accessoriesType={avatarData.accessoriesType}
                                hairColor={avatarData.hairColor}
                                facialHairType={avatarData.facialHairType}
                                clotheType={avatarData.clotheType}
                                clotheColor={avatarData.clotheColor}
                                eyeType={avatarData.eyeType}
                                eyebrowType={avatarData.eyebrowType}
                                mouthType={avatarData.mouthType}
                                skinColor={avatarData.skinColor}
                            />
                        )}
                    </Div>
                </div>
            ) : (
                <Image
                    src={image}
                    className="avatar-img"
                    style={{
                        width: `${size}px`,
                        height: `${size}px`,
                        border: `5px solid ${border}`,
                    }}
                />
            )}
        </div>
    );
}
