import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    queue: [],
    lastPushed: null,
    lastUpdated: null,
    pushable: false,
};

export const trackerSlice = createSlice({
    name: "tracker",
    initialState,
    reducers: {
        trackClear: (state) => {
            const herenow = new Date();
            return {
                ...initialState,
                lastPushed: herenow.toLocaleString("en-IN"),
                lastUpdated: state.lastUpdated,
            };
        },
        trackQuestion: (state, action) => {
            createTracker("QUESTION_RESPONSE", state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase("auth/login", (state) => createTracker("LOGIN", state, {}))
            .addCase("auth/logout", (state) =>
                createTracker("LOGOUT", state, {})
            )
            .addCase("attempt/startSession", (state, action) => {
                const todayDate = new Date();
                const lastLocalSession =
                    window.localStorage.getItem("last_session");

                // Convert the last session dates from the server and local storage to Date objects
                const lastServerSessionDate = action.payload.last_session
                    ? new Date(action.payload.last_session)
                    : null;
                const lastLocalSessionDate = lastLocalSession
                    ? new Date(lastLocalSession)
                    : null;

                // Determine if a new session should be added based on the server's last session date
                // This checks if the current date (with time set to 00:00:00) is greater than the server's last session date (with time set to 00:00:00)
                const serverAddSession =
                    lastServerSessionDate &&
                    todayDate.setHours(0, 0, 0, 0) >
                        lastServerSessionDate.setHours(0, 0, 0, 0);
                // Determine if a new session should be added based on the local last session date
                // This checks if there is no local session date or if the current date (with time set to 00:00:00) is greater than the local last session date (with time set to 00:00:00)
                const localAddSession =
                    lastLocalSessionDate == null ||
                    todayDate.setHours(0, 0, 0, 0) >
                        lastLocalSessionDate.setHours(0, 0, 0, 0);

                if (serverAddSession || localAddSession) {
                    // If it is a new day, add a new session tracker
                    createTracker("SESSION_STARTED", state, {});
                    state.pushable = true;
                }
                // Update the local storage with the current date to mark the last session date
                window.localStorage.setItem("last_session", todayDate);
                // Set the state to be pushable
                return state;
            })
            .addCase("attempt/startAttempt", (state, action) => {
                if (action.payload.deck)
                    createTracker("DECK_STARTED", state, {
                        deck: action.payload.deck,
                    });
            })
            .addCase("attempt/startArticleAttempt", (state, action) => {
                if (action.payload.id)
                    createTracker("ARTICLE_STARTED", state, {
                        article: action.payload.id,
                        deck: action.payload.deckId,
                    });
            })
            .addCase("attempt/completeDeck", (state, action) => {
                createTracker("DECK_COMPLETED", state, {
                    deck: action.payload.deck,
                });
                if (action.payload.courseState?.currentState?.completion)
                    createTracker("COURSE_COMPLETED", state, {
                        course: action.payload.course,
                    });
                const payload = createPayload(action.payload);
                createTracker("PUSH_STATE", state, payload);
                state.pushable = true;
                return state;
            })
            .addCase("attempt/abandonDeck", (state, action) => {
                createTracker("DECK_ABANDONED", state, {
                    deck: action.payload.deck,
                });
                const payload = createPayload(action.payload);
                createTracker("PUSH_STATE", state, payload);
                state.pushable = true;
                return state;
            })

            .addDefaultCase(() => {});
    },
});

const createPayload = (payload) => {
    const pushState = {
        deck: payload.deck,
        readerType: payload.readerType,
        deckState: payload.deckState,
        learnerState: payload.learnerState,
    };
    if (payload.article) {
        pushState.article = payload.article;
        pushState.articleState = payload.articleState;
    }
    if (payload.course) {
        pushState.topic = payload.topic;
        pushState.course = payload.course;
        pushState.program = payload.program;
        pushState.topicState = payload.topicState;
        pushState.courseState = payload.courseState;
        pushState.programState = payload.programState;
    }
    return pushState;
};

const createTracker = (name, state, payload, time) => {
    state.queue.push({
        name: name,
        payload: _.omit(payload, ["pushable"]),
        time: time || Date.now(),
    });
    const herenow = new Date();
    state.lastUpdated = herenow.toLocaleString("en-IN");
    state.pushable = payload.pushable || false;
    return state;
};

// Action creators are generated for each case reducer function
export const {
    trackClear,
    trackLogin,
    trackLogout,
    trackQuestion,
    trackPushState,
} = trackerSlice.actions;

export default trackerSlice.reducer;
