/**
 * AbstractImageGenerator is a React component that generates an abstract image
 * with a pastel color background and a set of icons based on the provided props.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.readerType - The type of reader to determine the icon set.
 * @param {string} props.name - The name used to generate a unique pastel color.
 * @param {boolean} [props.iconRows=true] - Flag to determine if icon rows should be displayed.
 *
 * @returns {JSX.Element} A styled div containing the abstract image with icons.
 */

import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { gradient } from "@utilities/helpers";
import { deckFormats } from "@schemas/deckFormats";

export default function AbstractImageGenerator(props) {
    const { readerType, name, iconRows = true } = props;
    const iconList =
        _.find(deckFormats, { category: readerType })?.icons ||
        _.find(deckFormats, { readerType: readerType })?.icons ||
        deckFormats.default.icons;

    const saturation = 50; // Adjust this value (0 to 100) for desired saturation
    const lightness = 30; // Adjust this value (0 to 100) for desired lightness
    const selectedColor = stringToPastelColor(name, saturation, lightness);

    return (
        <Div
            fluid
            relative
            noOverflow
            clearfix
            style={{
                backgroundColor: gradient(selectedColor, null, true),
                backgroundImage: `repeating-radial-gradient(circle at 0 ${parseInt(
                    Math.random() * 100
                )}px, transparent 0, ${selectedColor}ff 10px), repeating-linear-gradient(${selectedColor}44, ${selectedColor})`,
            }}
            className="article-abstract-container"
        >
            <Div className="article-abstract-main-icon">
                <i className={`icon icm icon-${iconList[0]}`} />
            </Div>
            <Div className="article-abstract-back">
                {iconRows &&
                    _.times(4, (n) => {
                        return (
                            <Div
                                key={`abstract-row-${n}`}
                                className="abstract-row"
                            >
                                {_.map(_.shuffle(iconList), (icon, index) => (
                                    <i
                                        key={`abstract-row-icon-${n}-${index}`}
                                        className={`icon icm icon-${icon}`}
                                    />
                                ))}
                                {_.map(_.shuffle(iconList), (icon, index) => (
                                    <i
                                        key={`abstract-row-icon-${n}-${index}`}
                                        className={`icon icm icon-${icon}`}
                                    />
                                ))}
                            </Div>
                        );
                    })}
            </Div>
        </Div>
    );
}

export function stringToPastelColor(text, saturation, lightness) {
    // Step 1: Convert the text string to a numerical value (hash)
    const hash = hashCode(text);

    // Step 2: Map the hash value to a color space (HSL)
    const hue = hash % 360; // Use the hash value as the hue

    // Step 3: Calculate pastel color components
    const rgbColor = hslToRgb(hue / 360, saturation / 100, lightness / 100);
    const clampedRgbColor = clampRgb(rgbColor[0], rgbColor[1], rgbColor[2]);
    const hexColor = rgbToHex(
        clampedRgbColor[0],
        clampedRgbColor[1],
        clampedRgbColor[2]
    );

    return hexColor;
}

// Hashing function (SHA-256)
export function hashCode(text) {
    let hash = 0;
    if (text?.length === 0) {
        return hash;
    }
    for (let i = 0; i < text?.length; i++) {
        const char = text?.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32-bit integer
    }
    return hash;
}
// Clamp RGB values to the valid range (0-255)
export function clampRgb(r, g, b) {
    const clamp = (value) => Math.min(Math.max(value, 0), 255);
    return [clamp(r), clamp(g), clamp(b)];
}

// HSL to RGB conversion
export function hslToRgb(h, s, l) {
    // Normalize hue to [0, 1]
    h = ((h % 1) + 1) % 1;
    // Clamp saturation and lightness to [0, 1]
    s = Math.min(Math.max(s, 0), 1);
    l = Math.min(Math.max(l, 0), 1);

    let r, g, b;

    if (s === 0) {
        // Achromatic (gray scale)
        r = g = b = l;
    } else {
        const hueToRgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, h + 1 / 3);
        g = hueToRgb(p, q, h);
        b = hueToRgb(p, q, h - 1 / 3);
    }

    // Round values explicitly after scaling to avoid precision issues
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// RGB to hexadecimal conversion
export function rgbToHex(r, g, b) {
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
