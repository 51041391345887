import React from "react";
import _ from "lodash";
import { Image as SImage, Icon as SIcon } from "semantic-ui-react";
import { StyledDiv, StyledButton } from "@config/styles/app.styles";
import "@styles/icomoon.css";
import { settings } from "@config/settings/app.settings";
import { lightOrDark } from "../utilities/helpers";
import * as DOMPurify from "dompurify";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

export function Div(props) {
    const bgColor = props.primary
        ? window.branding?.colors?.primary
        : props.main
        ? window.branding?.colors?.main
        : props.bgColor;
    const txtColor = props.primary
        ? window.branding?.colors?.primaryText
        : props.txtPrimary
        ? window.branding?.colors?.primary
        : props.txtColor;

    const passedProps = {
        ..._.omit(props, ["primary", "main", "txtPrimary"]),
        bgColor,
        txtColor,
    };
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            priText
            {...passedProps}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function UDiv(props) {
    const bgColor = props.primary ? props.main : props.bgColor;
    const txtColor = props.primary ? props.txtPrimary : props.txtColor;

    const passedProps = {
        ..._.omit(props, ["primary", "main", "txtPrimary"]),
        bgColor,
        txtColor,
    };
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            priText
            {...passedProps}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function HDiv(props) {
    const bgColor = props.primary
        ? window.branding?.colors?.primary
        : props.main
        ? window.branding?.colors?.main
        : props.bgColor;
    const txtColor = props.primary
        ? window.branding?.colors?.primaryText
        : props.txtPrimary
        ? window.branding?.colors?.primary
        : props.txtColor;

    const passedProps = {
        ..._.omit(props, ["primary", "main", "txtPrimary"]),
        bgColor,
        txtColor,
    };
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    const contentSegments = parseHTMLAndLatex(passedProps.content);

    if (settings.functionality.latexEnabled)
        return (
            <StyledDiv
                priText
                {...passedProps}
                {...(animation
                    ? { initial: animation.from, animate: animation.to }
                    : {})}
            >
                {contentSegments.map((segment, index) =>
                    segment.type === "latex" ? (
                        <Latex key={`latex-${index}`}>
                            ${segment.content}$
                        </Latex>
                    ) : (
                        <span
                            key={`html-${index}`}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(segment.content, {
                                    ADD_ATTR: ["target", "rel"],
                                    ADD_TAGS: ["iframe"],
                                }),
                            }}
                        />
                    )
                )}
            </StyledDiv>
        );
    else
        return (
            <StyledDiv
                priText
                {...passedProps}
                {...(animation
                    ? { initial: animation.from, animate: animation.to }
                    : {})}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(passedProps.content, {
                        ADD_ATTR: ["target", "rel"],
                        ADD_TAGS: ["iframe"],
                    }),
                }}
            />
        );
}

export function Icomoon(props) {
    return (
        <i
            className={`icm icon-${props.name}`}
            style={{ color: props.color }}
        />
    );
}

export function Button(props) {
    const bgcolor = props.primary
        ? window.branding?.colors?.primary
        : props.main
        ? window?.branding?.colors?.main
        : undefined;
    const txtcolor = props.primary
        ? window.branding?.colors?.primaryText != null
            ? window.branding?.colors?.primaryText
            : window.branding?.colors?.primary &&
              lightOrDark(window.branding?.colors?.primary) === "light"
            ? "#000000"
            : "#ffffff"
        : props.txtPrimary
        ? window.branding?.colors?.primary
        : undefined;

    const passedProps = {
        ..._.omit(props, ["primary", "main", "txtPrimary"]),
        bgcolor,
        txtcolor,
    };
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledButton
            {...passedProps}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function Container(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            fullht
            fluid
            rounded
            centered
            layer="1"
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function AbsWrapper(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            absolute
            fullht
            fluid
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
            style={{ top: 0, left: 0, ...props.style }}
        />
    );
}
export function CenteredContent(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            absolute
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
            style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                ...props.style,
            }}
        />
    );
}
export function Overlay(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            absolute
            fullht
            fluid
            priText
            layer={1000}
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
            style={{ top: 0, left: 0, ...props.style }}
        />
    );
}
export function Image(props) {
    const handleError = (event) => {
        // Replace the image source with the placeholder image
        event.target.src = "/assets/images/configurable/missing.jpg";
    };
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            {..._.omit(props, "src")}
            nopad
            noOverflow
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        >
            <SImage
                src={
                    props.src
                        ? props.src
                        : "/assets/images/configurable/missing.jpg"
                }
                alt={props.alt}
                style={{
                    ...props.styles,
                    width: props.fluid ? "100%" : "auto",
                }}
                className={props.className}
                onError={handleError}
            />
        </StyledDiv>
    );
}
export function Icon(props) {
    const bgColor = props.primary
        ? window.branding?.colors?.primary || settings.colors.primary
        : props.main
        ? window.branding?.colors?.main || settings.colors.main
        : undefined;
    const txtColor = props.primary
        ? window.branding?.colors?.primaryText || settings.colors.primaryText
        : props.txtPrimary
        ? window.branding?.colors?.primary || settings.colors.primary
        : undefined;

    const passedProps = {
        ..._.omit(props, ["primary", "main", "txtPrimary"]),
        bgColor,
        txtColor,
    };
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            {..._.omit(passedProps, "name")}
            noOverflow
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        >
            <SIcon
                name={props.name}
                styles={props.styles}
                className={props.className}
            />
        </StyledDiv>
    );
}

function getAnimation(props) {
    let animObj = {};
    if (props.zoom) animObj.animation = "zoom";
    else if (props.fadeAnim) animObj.animation = "fade";
    else if (props.slideDown) animObj.animation = "slideDown";
    else if (props.slideUp) animObj.animation = "slideUp";
    else if (props.slideLeft) animObj.animation = "slideLeft";
    else if (props.slideRight) animObj.animation = "slideRight";
    else if (props.collapse) animObj.animation = "collapse";
    else if (props.popAnimation) animObj.animation = props.popAnimation;

    if (props.slowAnim) {
        animObj.duration = 0.5;
        animObj.delay = 0.3;
    }
    if (props.delayAnim) {
        animObj.duration = 0.5;
        animObj.delay = 0.6;
    }

    let transitionObj = {
        duration: animObj.duration || 0.25,
        delay: animObj.delay || 0,
        staggerChildren: 0.5,
    };

    if (animObj?.animation) {
        const initial = {
            zoom: {
                scale: 0,
            },
            fade: {
                opacity: 0,
            },
            slideRight: {
                opacity: 0,
                x: -80,
            },
            slideDown: {
                opacity: 0,
                y: -40,
            },
            slideUp: {
                opacity: 0,
                y: 40,
            },
            slideLeft: {
                opacity: 0,
                x: 80,
            },
            collapse: {
                scale: 5,
                opacity: 0,
            },
        };
        const final = {
            zoom: {
                scale: 1,
                transition: transitionObj,
            },
            fade: {
                opacity: 1,
                transition: transitionObj,
            },
            slideRight: {
                x: 0,
                opacity: 1,
                transition: transitionObj,
            },
            slideDown: {
                y: 0,
                opacity: 1,
                transition: transitionObj,
            },
            slideUp: {
                y: 0,
                opacity: 1,
                transition: transitionObj,
            },
            slideLeft: {
                x: 0,
                opacity: 1,
                transition: transitionObj,
            },
            collapse: {
                scale: 1,
                opacity: 1,
                transition: transitionObj,
            },
        };
        return {
            from: initial[animObj.animation],
            to: final[animObj.animation],
            transition: animObj.transition,
        };
    }
}

const parseHTMLAndLatex = (html = "") => {
    const segments = [];
    const regex = /\$(.*?)\$/g;

    let lastIndex = 0;
    html.toString().replace(regex, (match, latexContent, index) => {
        // Add the preceding HTML part
        if (index > lastIndex) {
            segments.push({
                type: "html",
                content: html.substring(lastIndex, index),
            });
        }
        // Add the LaTeX part
        segments.push({ type: "latex", content: latexContent });
        lastIndex = index + match.length;
    });

    // Add any remaining HTML part after the last LaTeX segment
    if (lastIndex < html.length) {
        segments.push({ type: "html", content: html.substring(lastIndex) });
    }

    return segments;
};
