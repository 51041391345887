// Import from NPM
// -------------------------------------
import React from "react";
import { useFeatures } from "flagged";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "@styles/courseLayout.scss";
import { Dropdown } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function NavDropdown(props) {
    const { isolated, user, resyncData } = props;
    const features = useFeatures();
    const navigate = useNavigate();
    const isPortrait = window.innerWidth < window.innerHeight;
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Dropdown
            pointing
            direction="left"
            icon="ellipsis vertical"
            className="ellipsis"
        >
            <Dropdown.Menu>
                <Dropdown.Header>{`${user.first_name} ${user.last_name}`}</Dropdown.Header>
                <Dropdown.Divider />
                {features.general.allowDarkMode && (
                    <Dropdown.Item
                        icon={"adjust"}
                        text={
                            features.darkMode
                                ? t("navbar.lightMode")
                                : t("navbar.darkMode")
                        }
                        onClick={() => {
                            window.localStorage.setItem(
                                "darkMode",
                                features.darkMode ? "false" : "true"
                            );
                            window.location.reload();
                        }}
                    />
                )}
                <Dropdown.Item
                    icon={"pencil"}
                    text={t("navbar.editProfile")}
                    onClick={() =>
                        navigate(
                            `/editProfile${isolated ? "/update" : "/avatar"}`
                        )
                    }
                />
                <Dropdown.Divider />
                <Dropdown.Item
                    icon={"sync"}
                    text={t("navbar.sync")}
                    onClick={resyncData}
                />
                {features.ability.builder.visible && !isPortrait && (
                    <Dropdown.Item
                        icon={"object group"}
                        text={t("navbar.creatorPanel")}
                        as="a"
                        href={`${settings.targetRoute}builder`}
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                )}
                {features.ability.adminPanel.visible && !isPortrait && (
                    <Dropdown.Item
                        icon={"dashboard"}
                        text={t("navbar.adminPanel")}
                        as="a"
                        href={`${settings.targetRoute}adminPanel`}
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                )}
                {features.learningCenter && (
                    <Dropdown.Item
                        icon={"bookmark"}
                        text={t("article.favoritesView")}
                        onClick={() => navigate("/favorites")}
                    />
                )}
                {isPortrait && settings.functionality.helpdeskActive && (
                    <Dropdown.Item
                        icon={"headphones"}
                        text={t("navbar.needHelp")}
                        onClick={() => navigate("/app/helpdesk")}
                    />
                )}
                <Dropdown.Divider />
                {!settings.functionality.noLogout && (
                    <Dropdown.Item
                        icon={"log out"}
                        text={t("navbar.logout")}
                        onClick={() => {
                            const exitRoute = settings.auth.routes.logout;
                            if (exitRoute.includes("http"))
                                window.location.href = exitRoute;
                            else navigate(exitRoute);
                        }}
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}
