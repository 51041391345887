import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Grid, Image, Button } from "semantic-ui-react";
import { UDiv } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

export default function ErrorPage(props) {
  const { errorMsg } = props;
  const { t } = useTranslation("common");

  // Function for navigation using window.location
  const navigateTo = (path) => {
    if (path === -1) {
      window.history.back();
    } else {
      window.location.href = path;
    }
  };

  // ========================= Render Function =================================
  return (
    <UDiv fluid pageht style={darkStyle(settings.colors.main)}>
      <Helmet>
        <title>{t("components.errorHeader")}</title>
      </Helmet>
      <UDiv fluid pageht huge flex>
        <UDiv wd="80vw">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <UDiv giant altText center-align>
                  <Image fluid src="/assets/images/configurable/error.png" />
                </UDiv>
              </Grid.Column>
              <Grid.Column width={8}>
                <UDiv flex column fullht>
                  <UDiv>
                    <UDiv large altText uppercase gutter>
                      {t("components.errorHeader")}
                    </UDiv>
                    <UDiv trench>{errorMsg || t("components.errorMsg")}</UDiv>
                    <Button
                      primary
                      content={t("components.goBack")}
                      onClick={() => {
                        navigateTo(-1);
                      }}
                    />
                    {settings.functionality.helpdeskActive && (
                      <Button content={t("components.helpdeskReport")} />
                    )}
                    {!settings.functionality.noLogout && (
                      <Button
                        danger={+true}
                        content={t("components.exit")}
                        onClick={() => {
                          navigateTo("/logout");
                        }}
                      />
                    )}
                  </UDiv>
                </UDiv>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </UDiv>
      </UDiv>
    </UDiv>
  );
}
